import { Controller } from "@hotwired/stimulus"
import { debounce } from 'lodash';

export default class extends Controller {
  connect() {
    // prevent double initialization controller
    // selectize clone html with attached controller
    if(document.querySelector(`#${this.element.id}-selectized`)) return;

    let $element = $(this.element)

    const url = this.data.get('url')
    const preload = !!this.data.get('preload')
    const nested = this.data.get('nested') || true // send to nested form. This allow properly form response
    const multiple = this.data.get('multiple')
    const create = this.data.get('create') || true
    const edit = !(this.data.get('edit') === 'false')
    const removeButton = this.data.get('remove')
    const disableItems = this.data.get('disableItems') || false // selected item will be not added to selectize. This is useful when you want your selectize be always empty but selected item will be showed in another place

    // add edit customer icon
    if(edit) {
      $element.parent().find('.form-label').append('<a href="#" id="edit-customer-icon" class="ml-2 d-none"><div class="mdi mdi-pencil-outline d-inline"></div></a>')
    }
    const editIcon = $('#edit-customer-icon')
    var preloaded = false

    let selectOptions = {
      persist: false,
      maxItems: (multiple ? null: 1),
      closeAfterSelect: true,
      dropdownParent: null,
      preload: preload,
      highlight: false,

      // add data attribute to option
      onInitialize: function () {
        var s = this;
        this.revertSettings.$children.each(function () {
          $.extend(s.options[this.value], $(this).data());
        });

        if($element.val()) {
          selectOptions.enableEditButton($element.val())
        }
      },

      onChange: (value) => {
        const event = new CustomEvent("change", { detail: this.select.options[value] })
        this.element.dispatchEvent(event)

        if(disableItems) this.select.clear(true)

        if(value) {
          // allow edit customer
          selectOptions.enableEditButton(value)
          // save recent customer
          $.post(`/customers/${value}/make_recent`, {_method: 'patch'})
        }
        else {
          editIcon.addClass('d-none')
        }
      },

      // fix unwanted results filtering issue, results should stay the same as it coming from server (https://stackoverflow.com/questions/32378962/how-can-i-disable-automatic-filtering-in-selectize-js-built-in-plugin-modil)
      score: function() { return function() { return 1 } },

      enableEditButton: (value) => {
        editIcon.removeClass('d-none')
        editIcon.prop('href', `/customers/${value}/edit?nested=${encodeURIComponent(nested)}`)
      },

      load: function(query, callback) {
        if ( !preload ) {
          if (!query.length) return callback()
        }

        // fix unwanted results filtering issue, clear options before each load, otherwise options will stay the same when query changed (https://github.com/selectize/selectize.js/issues/1079)
        if(!preload || preloaded) {
          this.clearCache('option')
          this.clearOptions()
          this.refreshOptions(true)
        }
        // preloaded is workaround to not clear current selected on preload
        preloaded = true;

        $.ajax({
          url: url,
          type: 'GET',
          data: { q: query },
          error: () => { callback() },
          success: (res) => { callback(res) }
        })
      },
    }

    if(removeButton) selectOptions.plugins = ['remove_button'];

    if(create) {
      // use debounce as workaround for selectize bug https://github.com/selectize/selectize.js/issues/2108
      selectOptions.create = debounce(function(input, callback) {
        RemoteModal.show('customer-form', `/customers/new?nested=${encodeURIComponent(nested)}&name=${encodeURIComponent(input)}`, {
          nested: nested,
          success: (data) => {
            this.$dropdown.hide();
            callback(data)
          },
          hidden: () => {
            this.$dropdown.hide();
            callback([]);
          }
        })
      }, 100, {'leading': true, 'trailing': false})
    }

    editIcon.on('click', function (e) {
      RemoteModal.show('customer-form', editIcon.prop('href'), {
        nested: nested,
        success: (data) => {
          let idAddressValues = $element.val().split('-')
          let elementValue = data['text']

          // find new customer name with address in brackets
          if(idAddressValues.length > 1) {
            data['data']['addresses'].forEach((address)=> {
              if(address['value'].toString() === idAddressValues[1]) {
                elementValue = elementValue.replace(/\(.*\)/, `(${address['text']})`)
              }
            })
          }
          // name or address may change, update option
          $element[0].selectize.updateOption($element.val(), {value: $element.val(), text: elementValue});

          // Fire event to populate addresses select (appointments only)
          const event = new CustomEvent("change", {detail: data})
          $element[0].dispatchEvent(event)
        }
      })
      e.preventDefault()
    })

    this.select = $element.selectize(selectOptions)[0].selectize;
  }
}
