import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    keepEmpty: Boolean, // don't change select after choose option
    create: { type: Boolean, default: true },
  };

  connect() {
    // prevent double initialization controller
    // selectize clone html with attached controller
    if(document.querySelector(`#${this.element.id}-selectized`)) return;

    let $element = $(this.element);

    let select = $element.selectize({
      persist: false,
      maxItems: 1,
      closeAfterSelect: true,
      dropdownParent: null,
      highlight: false,
      addPrecedence: true,
      // fix: add data attribute to option
      onInitialize: function () {
        var s = this;
        this.revertSettings.$children.each(function () {
          if (this.tagName == "OPTION")
            $.extend(s.options[this.value], $(this).data());
          else if (this.tagName == "OPTGROUP") {
            $(this.children).each(function () {
              $.extend(s.options[this.value], $(this).data());
              s.options[this.value].data = $(this).data();
            });
          }
        });
      },
      onChange: (value) => {
        if (this.keepEmptyValue) select.clear(true);

        let options = { value: value };

        if (select.options[value]) {
          options["content"] = select.options[value].content;
          options["users"] = select.options[value].users;
          options["mobile"] = select.options[value].mobile;
          options["duration"] = select.options[value].duration;
          options["durationFormat"] = select.options[value].durationFormat;
          options["bufferTime"] = select.options[value].bufferTime || 0;
          options["amount"] = select.options[value].amount;
          options["amountFormat"] = select.options[value].amountFormat;
        }

        const event = new CustomEvent("change", { detail: options });
        this.element.dispatchEvent(event);
      },
      render: {
        option: function (item, escape) {
          var mobileIcon = item.mobile ? "<i class='icon-mobile-service sm ml-2'></i>" : "";
          var amountFormat = item.amountFormat ? `<span class="pl-2 text-muted">${item.amountFormat}</span>` : ""

          return `<div class="option">
          <span>${escape(item.text)}${mobileIcon}</span>
          <span class="right">${item.durationFormat}</span>
          ${amountFormat}
          </div>`;
        },
      },
      create: this.createOption(),
    })[0].selectize;
  }

  createOption() {
    if (!this.createValue) return false;

    return function (input, callback) {
      RemoteModal.show(
        "price_list_item_form",
        `/price_list_items/new?nested=true&name=${encodeURIComponent(input)}`,
        {
          nested: true,
          success: (data) => {
            if (!data.duration) data = {};
            this.$dropdown.hide();
            callback(data);
          },
          hidden: () => {
            this.$dropdown.hide();
            callback([]);
          },
        }
      );
    };
  }
}
