import { Controller } from "@hotwired/stimulus";
import { debounce } from 'lodash';

export default class extends Controller {
  static values = {
    currency: { type: String, default: 'USD' }
  }

  static targets = [
    "service",
    "product",
    "date",
    "customer",
    "address",
    "serviceAmount",
    "total",
    "newAddressForm",
    "submit",
  ];

  initialize() {
    this.timeSlots = {};
    this.currencyFormatter = Intl.NumberFormat('en', {style: 'currency', currency: this.currencyValue})
  }

  connect() {
    let self = this;
    this.selectAddress = $(this.addressTarget).selectize({
      persist: true,
      create: debounce(function(_input, callback) {
        RemoteModal.show(
          "address-form",
          `/customers/${encodeURIComponent(
            self.customerTarget.value
          )}/addresses/new?nested=true`,
          {
            nested: true,
            success: (data) => {
              this.$dropdown.hide();
              callback(data);
            },
            hidden: () => {
              this.$dropdown.hide();
              callback([]);
            },
          }
        );
      }, 100, {'leading': true, 'trailing': false}),
    })[0].selectize;

    this.datePicker = flatpickr(`#${this.dateTarget.getAttribute("id")}`, {
      onChange: this.onDateSelect.bind(this),
      // onMonthChange: this.onMonthChange.bind(this),
    });

    if (!this.dateTarget.value) this.datePicker.setDate(moment().format());

    this.calculateTotal();
  }

  onDateSelect(selectedDates, dateStr, instance) {
    this.selectedDate = dateStr;

    const event = new CustomEvent("appointment-form.date-changed", {
      detail: { date: dateStr },
    });
    window.dispatchEvent(event);
  }

  onAppointmentRemoved(e) {
    this.toggleAddressField();
    this.calculateTotal();
  }

  onAppointmentAdded(e) {
  }

  onChangeService(e) {
    this.toggleAddressField();
  }

  onChangeServiceAmount(e) {
    this.calculateTotal();
  }

  onProductChanged(e) {
    this.calculateTotal();
  }

  calculateTotal() {
    let total = 0;
    this.serviceAmountTargets.forEach((el) => {
      if (el.value) {
        total += parseFloat(el.value.replace(/,/g, ''));
      }
    });

    this.productTargets.forEach((el) => {
      if (el.controller) {
        total += el.controller.amount;
      }
    });
    this.totalTarget.innerText = this.currencyFormatter.format(total);
  }

  onChangeCustomer(e) {
    if (!e.detail) {
      this.selectAddress.clear(true);
      this.selectAddress.clearOptions();
      this.toggleAddressField();
      return false;
    }

    let data = e.detail.data;

    this.selectAddress.clear(true);
    this.selectAddress.clearOptions();
    if (data && data.addresses) {
      data.addresses.forEach((i) => this.selectAddress.addOption(i));
      if (data.addresses[0]) {
        this.selectAddress.setValue(data.addresses[0].value);
      }
    }

    // this.enableFormFields()
    this.toggleAddressField();
  }

  enableFormFields() {
    if (
      this.dateTarget.value &&
      this.timeTarget.value &&
      this.customerTarget.value
    ) {
      this.submitTarget.removeAttribute("disabled");
    } else {
      this.submitTarget.setAttribute("disabled", true);
    }
  }

  toggleAddressField() {
    let enabled = false;
    for (let el of this.serviceTargets) {
      if (el.controller && el.controller.isMobile()) {
        enabled = true;
        break;
      }
    }

    if (enabled) {
      this.selectAddress.enable();
      this.addressTarget.parentNode.classList.remove("d-none");
    } else {
      this.selectAddress.disable();
      this.addressTarget.parentNode.classList.add("d-none");
    }
  }

  // initTimeSlots() {
  //   this.loadTimeSlots(() => {
  //     let initialDate = this.datePicker.selectedDates[0];
  //     if(initialDate) {
  //       initialDate = moment(this.datePicker.selectedDates[0]).format('Y-MM-DD');
  //     }

  //     let dates = $.map(this.timeSlots, function(_, key){ return key; }).sort();

  //     if(dates.length > 0) {
  //       if(initialDate && this.timeSlots[initialDate]) {
  //         this.datePicker.setDate(moment(initialDate).format(), true);
  //         this.datePicker.jumpToDate(initialDate, true);
  //       } else {
  //         this.datePicker.setDate(moment(dates[0]).format(), true);
  //         this.datePicker.jumpToDate(dates[0], true);
  //       }
  //     }

  //     this.enableFormFields();
  //   });
  // }

  // onMonthChange(selectedDates, dateStr, instance) {
  //   this.loadTimeSlots();
  // }

  // onDateSelect(selectedDates, dateStr, instance) {
  //   this.selectedDate = dateStr;
  //   // this.renderTimes();
  // }

  // loadTimeSlots(callback) {
  //   if(!this.serviceTarget.value) {
  //     if(callback) { callback() }
  //     return;
  //   }

  //   let monthDate = `${this.datePicker.currentYear}-${this.datePicker.currentMonth+1}-01`

  //   $.ajax({
  //     url: '/appointments/time_slots',
  //     type: 'GET',
  //     data: {
  //       month_date: monthDate,
  //       service: this.serviceTarget.value,
  //       user: this.userTarget.value,
  //       // appointment: this.timeTarget.dataset.appointment,
  //       today: moment().format()},
  //     success: (times) => {
  //       this.timeSlots = times;

  //       times = $.map(times, function(_, key){ return key; });
  //       this.datePicker.set('enable', times);

  //       if(callback) callback();
  //     }
  //   });
  // }

  // renderTimes() {
  //   if(!this.selectedDate) return false;
  //   let options = ""
  //   let times = this.timeSlots[this.selectedDate]
  //   if(times) {
  //     for(let i = 0; i < times.length; i++) {
  //       if(times[i] == this.timeTarget.value) {
  //         options += `<option value="${times[i]}" selected>${times[i]}</option>`;
  //       } else {
  //         options += `<option value="${times[i]}">${times[i]}</option>`;
  //       }
  //     }
  //   } else {
  //     options += `<option value="">No times available</option>`;
  //   }
  //   $(this.timeTarget).html(options);
  // }
}
