import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "quantity",
    "unitAmount"
  ];

  connect() {
    this.element["controller"] = this;
  }

  productChanged(e) {
    this.unitAmountTarget.value = e.detail.amount;
    this.changed();
  }

  changed() {
    this.element.dispatchEvent(new Event("change", { bubbles: true }));
  }

  get amount() {
    return parseFloat(this.quantityTarget.value) * parseFloat(this.unitAmountTarget.value.replace(/,/g, '')) || 0;
  }
}
