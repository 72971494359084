/**
 * Attachment control plugin. Upload multiple attachments
 */

if (!window.fbControls) window.fbControls = [];
window.fbControls.push(function(controlClass) {
  class controlAttachment extends controlClass {
    static get definition() {
      return {
        icon: "<i class='mdi mdi-file-multiple'></i>",
        i18n: {
          default: 'Attachments'
        },
        defaultAttrs: {
          'text': {
              label: "Text",
              value: 'Drag and drop here to upload or',
              type: 'text',
          },

          'button_title': {
              label: "Button title",
              value: 'Choose file',
              type: 'text',
          },
        }
      };
    }

    build() {
      const content = `<div class="dropzone dropzone-default dz-clickable autosubmit"><div class="gallery-images uploads plain-image-preview-section attachments border-0 p-0 justify-content-start"><div class="mt-4 ml-2"><div class="dz-message"><span class="ml-3">${this.config.text}<br><span class="mt-1 btn btn-outline-primary disabled">${this.config.button_title}</span></span></div></div></div></div>`;

      this.field = this.markup('div', content, this.config)
      return this.field;
    }
  }

  controlClass.register('attachment', controlAttachment);
  return controlAttachment;
});
